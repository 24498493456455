import React from 'react'
import { useLocation } from 'react-router-dom'

import MwsAndPpcSyncModals from '../containers/account/MwsAndPpcSyncModals'
import MwsSyncStatusbarContainer from '../containers/account/MwsSyncStatusbar'

const MwsSyncStatusbar = ({ membershipInfo, sidebarOpen }) => {
  const location = useLocation()

  return (
    <>
      <MwsAndPpcSyncModals />

      {membershipInfo && (
        <MwsSyncStatusbarContainer
          sidebarOpen={sidebarOpen}
          location={location}
        />
      )}
    </>
  )
}

export default MwsSyncStatusbar
