import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { sendCtaClickEvent } from 'helpers/segment'
import { CONTACT_SUPPORT } from 'constants/segment'
import { Text } from '@junglescout/edna'

const Columns = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row; /* confusing I know, but it works */
  margin: auto;
  height: 289px;

  & > div:last-child {
    justify-content: center;
    align-items: center;
  }
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: ${props => props.basis};
  max-width: ${props => props.basis};
`
const Figure = styled.figure`
  max-height: 232px;
  max-width: 230px;
  img {
    max-height: 232px;
    max-width: 230px;
  }
`
const LeftBottom = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  height: 100%;
`
const Wrapper = styled.div`
  height: 100%;
  display: flex;
`

export function FourZeroFourPage() {
  const { t } = useTranslation(['pageNotFound'])

  return (
    <Wrapper>
      <Columns>
        <Column>
          <Text variant="heading4xl" margin="0 0 15px 0" element="h1">
            {t('pageNotFound:page.page.title', 'Oops!')}
          </Text>
          <Text variant="headingLg" element="h2">
            {t(
              'pageNotFound:page.subTitle',
              'We can’t seem to find the page you’re looking for.'
            )}
          </Text>
          <LeftBottom>
            <Text element="p" variant="bodySm">
              {t(
                'pageNotFound:page.supportText',
                'If you’re experiencing problems, connect to our support team!'
              )}
            </Text>
            <Text
              element="a"
              href="https://support.junglescout.com/"
              onClick={() =>
                sendCtaClickEvent({
                  destination: 'https://support.junglescout.com/',
                  text: CONTACT_SUPPORT,
                  location: '404'
                })
              }
              variant="bodySm"
              fontWeight="500"
              color="primary">
              {t('pageNotFound:page.contactLink', 'Contact Support')}
            </Text>
          </LeftBottom>
        </Column>
        <Column>
          <Figure>
            <img
              src="//djga94vwgu7gt.cloudfront.net/assets/junglescout/group-88-copy-2@3x.png"
              alt="404 confused person"
            />
          </Figure>
          <Text element="h3" variant="headingXl" color="primary">
            404
          </Text>
        </Column>
      </Columns>
    </Wrapper>
  )
}
